/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

const Day = styled.p`
  font-family: ${(props) => props.theme.font.family.secondary};
  font-size: ${(props) => props.theme.font.size.xl};
  color: ${(props) => props.theme.color.text.contrast};
  width: 10%;
`

const Markten = ({ columns }) => {
  const { markt } = useStaticQuery(graphql`
    {
      markt: allWordpressWpMarkten(sort: { fields: date }) {
        edges {
          node {
            acf {
              market {
                day
                placeTime: place_time
              }
            }
          }
        }
      }
    }
  `)

  return markt.edges.map(({ node: { acf } }, index) => (
    <div
      className={`${
        columns ? 'col-lg-4 align-items-start' : 'align-items-center'
      } d-flex mb-3`}
      key={index}
    >
      <Day className="mr-5">{acf.market.day}</Day>
      <ParseContent content={acf.market.placeTime} />
    </div>
  ))
}

export default Markten
